<template>
  <v-container>
    <!--<v-snackbar v-model="snackbar">
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="snackbar = false"> Okay </v-btn>
      </template>
    </v-snackbar>-->
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>
    <Tabs :tabs="configmenus" @settabvalue="settabvalue" />
    <!--<div>
      <v-row class="flex" centre>
        <v-btn
          small
          v-for="configmenu in configmenus"
          :key="configmenu"
          tile
          text
          color="secondary"
          @click="setmenu(configmenu)"
        >
          {{ configmenu }}
        </v-btn>
      </v-row>
    </div>-->

    <v-data-table
      :headers="headers"
      :items="configs"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      :show-expand="expandflag"
      class="elevation-1"
      :loading="notloaded"
      loading-text="Loading..."
      :search="search"
      :footer-props="{
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :items-per-page="roles.itemsPerPage"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ convertDate(item.date) }}
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Configuration </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on" @click.prevent="settiptop">ADD Config</v-btn>
            </template>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                <!-- <div v-if="alertflag">
                  <v-alert text color="info">
                    <v-row align="center" no-gutters>
                      <v-col cols="12" xs="10" sm="10" md="10" lg="10">{{ alertmsg }}</v-col>
                      <v-spacer></v-spacer>
                      <v-col>
                        <v-btn cols="12" xs="1" sm="1" md="1" lg="1" color="info" outlined @click="alertflag = false">
                          Okay
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </div>-->
                <v-card-text>
                  <v-container>
                    <div v-if="gen">
                      <v-row>
                        <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                          <div v-if="genedit">
                            <h4>Descriptions</h4>
                            {{ editedItem.description }}
                          </div>
                          <v-select
                            v-else
                            v-model="editedItem.description"
                            label="Descriptions"
                            :items="descriptions"
                            :rules="inputrequired"
                            @change="descriptionslected()"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                          <div>
                            <h4>Comments</h4>
                            {{ editedItem.comments }}
                          </div>
                        </v-col>

                        <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                          <v-text-field
                            v-if="confignumbertype"
                            v-model="editedItem.value"
                            label="Value"
                            :rules="decimalinput"
                            required
                          ></v-text-field>

                          <v-select
                            v-else
                            v-model="editedItem.value"
                            label="Option"
                            :items="YesNo"
                            :rules="inputrequired"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </div>

                    <div v-if="holiday">
                      <v-row>
                        <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                          <DatePicker
                            :menu="menuholiday"
                            :datevalue="editedItem.date"
                            :datelabeltext="'Holiday Date'"
                            @dateassinged="editedItem.date = $event"
                          />
                          <!--  <v-menu
                            v-model="menuholiday"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :rules="inputrequired"
                                v-model="editedItem.date"
                                label="Holiday Date"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.date" @input="menuholiday = false"></v-date-picker>
                          </v-menu>-->
                        </v-col>

                        <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.remarks"
                            label="Remarks"
                            :rules="inputrequired"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.type"
                            label="Type"
                            :rules="inputrequired"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>

                    <div v-if="pdp">
                      <v-row>
                        <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                          <v-text-field
                            v-model="editedItem.year"
                            label="Year"
                            :rules="inputrequired"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                          <v-select
                            v-model="editedItem.status"
                            label="Status"
                            :items="PDPstatus"
                            :rules="inputrequired"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                          <v-menu
                            v-model="menupdpeli"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :rules="inputrequired"
                                v-model="editedItem.eligibilitydate"
                                label="Eligibility Date"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.eligibilitydate"
                              @input="menupdpeli = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col  cols="12" xs="6" sm="6" md="6" lg="6">
                        <tipTapVuetify v-if="tiptab"
                          :tiptabheader="'Mail Message'"
                          :tiptablable="'Mail Message'"
                          :editflag=true
                          :tiptabstring="editedItem.mailMessage"
                          @assigntiptabvalues="editedItem.mailMessage = $event"
                        />
                      </v-col>
                      </v-row>
                    </div>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" text :disabled="!valid" @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Config</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  configgenRef,
  configpdpRef,
  holidaysRef,
  leavesummariesRef,
  configallRef,
  empRef,
  PDPRef,
  authdomain,
} from "../../fb"
import { formMixin } from "../../mixins/formMixin.js"
import { convertAll } from "../../mixins/convertall.js"
import { emailMixin } from "../../mixins/emailMixin.js"

import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import Spinner from "@/components/common/spinner.vue"
import Tabs from "@/components/common/Tabs.vue"
import DatePicker from "@/components/common/Datepicker.vue"
import tipTapVuetify from "@/components/common/tipTapVuetify.vue"

export default {
  name: "configmgt",
  //props: ["roles"],
  mixins: [formMixin, convertAll, emailMixin],
  components: { Snackbar, Alertmsg, Spinner, Tabs, DatePicker,tipTapVuetify },
  data: () => ({
    dialog: false,
    menuholiday: false,
    menupdpeli: false,
    // menugml: false,
    search: "",
    domain: "",
    lazy: false,
    valid: true,
    notloaded: true,
    singleExpand: false,
    expanded: [],
    expandflag: false,
    configmenus: ["General", "Holiday", "PDP"],
    headers: [],

    headersgen: [
      {
        text: "Description",
        align: "start",
        sortable: true,
        value: "description",
      },
      { text: "Value", value: "value" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    headersPDP: [
      {
        text: "Year",
        align: "start",
        sortable: true,
        value: "year",
      },
      { text: "Status", value: "status" },
      { text: "Eligibility Date", value: "eligibilitydate" },
      { text: "Mail Message ", value: "mailMessage" },
      
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersholiday: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
      { text: "Day", value: "day" },
      { text: "Remarks", value: "remarks" },
      { text: "Type", value: "type" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    inputrequired: [(v) => !!v || "Input is required"],
    decimalinput: [(v) => !!v || "Input is required", (v) => /^[0-9,.,,\s]+$/.test(v) || "Only Numbers and . allowed"],
    configs: [],
    configgen: [],
    configpdp: [],
    editedIndex: -1,

    editedItem: {},

    defaultItem: {}, // end of defaultItem

    PDPstatus: ["Half Yearly Open", "Half Yearly Completed", "Year End Open", "Year End Completed"],
    configall: [],
    comments: [],
    descriptions: [],
    configcomments: "",
    editflag: false,

    snackbar: false,
    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    gen: false,
    pdp: false,
    holiday: false,
    //gml: false, //Genarate Monthly leave
    configgens: [],
    configPDPs: [],
    configholidays: [],
    //configgmls: [],
    weekday: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    monarray: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    genedit: false,
    types: [],
    configtype: "",
    YesNo: ["YES", "NO"],
    configyesnotype: false,
    confignumbertype: false,
    roles: "",
    reviewpagemailarray: [],
    spinner: false,
    tiptab:false
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Config" : "Edit Config"
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.roles = await this.$store.state.userProfile

    console.log("config crated", this.roles)
    if (this.roles) {
      if (this.roles.role.superadmin) {
        this.editflag = true
        this.domain = this.roles.domain
        this.initialize()
      } else {
        alert("Not authorized to access this page , kindly contact support")
        this.$router.replace({ name: this.roles.menufrom })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },
  methods: {
    dateassinged(option, value) {
      if (option == "Holiday Date") this.editedItem.date = value
    },

    async removehtmltags(htmltagtext) {
      var newtext = ""
      newtext = await htmltagtext.replace(
        /(<u>|<\/u>|<s>|<\/s>|<ul>|<\/ul>|<em>|<\/em>|<strong>|<\/strong>|<\/strong>)/g,
        ""
      )
      newtext = await newtext.replace(/(<\/p>|<ol>|<\/ol>|<li>|<\/li>|<&nbsp;>)/g, " ")
      newtext = await newtext.replace(/(<p>)/g, "\n")
      return newtext
    },
    settiptop(){
      console.log("inside set tiptop")
      if (this.pdp){
        this.tiptab=true
      }
    },

    settabvalue(configmenu) {
      this.pdp = false
      this.gen = false
      this.holiday = false
      //  this.gml = false;
      if (configmenu == "PDP") {
        this.headers = this.headersPDP
        this.configs = this.configPDPs
        this.pdp = true
        this.editedItem = { description: "", value: "", domain: "", comments: "", type: ""}
        this.defaultItem = { description: "", value: "", domain: "", comments: "", type: "" }
      } else if (configmenu == "General") {
        this.headers = this.headersgen
        this.configs = this.configgens
        this.editedItem = {
          BPRatio: "",
          PL: "",
          SL: "",
          CL: "",
          CF: "",
          domain: "",
        }
        this.defaultItem = {
          BPRatio: "",
          PL: "",
          SL: "",
          CL: "",
          CF: "",
          domain: "",
        }
        this.gen = true
      } else if (configmenu == "Holiday") {
        this.headers = this.headersholiday
        this.configs = this.configholidays
        this.editedItem = {
          date: "",
          day: "",
          remarks: "",
          type: "",
          year: "",
          domain: "",
        }
        this.defaultItem = {
          date: "",
          day: "",
          remarks: "",
          type: "",
          year: "",
          domain: "",
        }
        this.holiday = true
      }
    },

    descriptionslected() {
      this.configyesnotype = false
      this.confignumbertype = false

      console.log("inside edited item gen", this.editedItem.description)
      var filteredItems = this.configall.filter((config) => {
        return config.description == this.editedItem.description
      })
      this.editedItem.comments = filteredItems[0].comments
      this.editedItem.type = filteredItems[0].type

      console.log("atter filter", filteredItems, this.editedItem.comments, this.editedItem.type)
      if (this.editedItem.type == "number") this.confignumbertype = true
      else if (this.editedItem.type == "YES/NO") this.configyesnotype = true
    },
    async initialize() {
      ;(this.configgens = []), (this.configPDPs = [])
      await configgenRef
        .where("domain", "==", this.domain)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.configgens.push({
              uid: doc.id,
              ...doc.data(),
            })
          })
        })

      await configpdpRef
        .where("domain", "==", this.domain)
        .orderBy("year", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.configPDPs.push({
              uid: doc.id,
              ...doc.data(),
            })
          })
        })
      var queryresult = await configallRef.where("user", "==", "domain").get()
      var tmpconfiggens = this.configgens
      for (var i = 0; i < queryresult.docs.length; ++i) {
        var description = queryresult.docs[i].data().description
        var a = (tmpconfiggens) => tmpconfiggens.description == description
        var valuefound = tmpconfiggens.some(a)
        if (!valuefound) {
          this.descriptions.push(queryresult.docs[i].data().description)
          this.comments.push(queryresult.docs[i].data().comments)
          this.types.push(queryresult.docs[i].data().type)
        }
        this.configall.push({
          description: queryresult.docs[i].data().description,
          comments: queryresult.docs[i].data().comments,
          type: queryresult.docs[i].data().type,
        })
      }

      console.log("Config all", this.configall)

      await holidaysRef
        .where("domain", "==", this.domain)
        .orderBy("year", "desc")
        .orderBy("date", "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.configholidays.push({
              uid: doc.id,
              ...doc.data(),
            })
          })
        })

      this.headers = this.headersgen
      this.configs = this.configgens
      this.gen = true
      this.notloaded = false
      this.editedItem = { description: "", value: "", domain: "", comments: "", type: ""}
      this.defaultItem = { description: "", value: "", domain: "", comments: "", type: "" }
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    editItem(item) {
      console.log("inside edititem",item)
     
      if (this.pdp == true)
          this.tiptab=true 
      if (this.gen) {
        this.editedIndex = this.configgens.indexOf(item)
        this.genedit = true
        this.configtype = ""
      } else if (this.pdp) this.editedIndex = this.configPDPs.indexOf(item)
      else if (this.holiday) this.editedIndex = this.configholidays.indexOf(item)
      // else if (this.gml) this.editedIndex = this.configgmls.indexOf(item);

      this.editedItem = Object.assign({}, item)

      if (this.gen) {
        this.descriptionslected()
      }

      this.dialog = true
      console.log("post assignment",this.editedItem)
    },

    async deleteItem(item) {
      var index
      if (this.gen) index = this.configgens.indexOf(item)
      else if (this.pdp) index = this.configPDPs.indexOf(item)
      else if (this.holiday) index = this.configholidays.indexOf(item)
      // else if (this.gml) index = this.configgmls.indexOf(item);
      const deletemsg = "Are you sure you want to delete this config?"
      const option = await this.deleteFromFb(deletemsg)

      console.log("inside deleteItem", option, index, item.serialNumber)
      if (option) {
        try {
          if (this.gen) {
            await configgenRef.doc(item.uid).delete()
            await this.configgens.splice(index, 1)
          } else if (this.pdp) {
            await configpdpRef.doc(item.uid).delete()
            await this.configPDPs.splice(index, 1)
          } else if (this.holiday) {
            await holidaysRef.doc(item.uid).delete()
            await this.configholidays.splice(index, 1)
          } /*else if (this.gml) {
            await gmlsRef.doc(item.uid).delete();
            await this.configgmls.splice(index, 1);
          }*/
          this.initialize()
        } catch (error) {
          console.log("Error while delete :", error)
        }
      }
    },

    close() {
      this.dialog = false
      this.genedit = false
      this.spinner = false
     
      this.tiptab=false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
      this.spinner = true
      if (this.holiday) {
        var tmpdate = new Date(this.editedItem.date)
        this.editedItem.day = this.weekday[tmpdate.getDay()]
        this.editedItem.year = tmpdate.getFullYear()
      } /* else if (this.gml) {
        this.editedItem.status = "Completed";
      }*/
      if (this.editedIndex > -1) {
        if (this.gen) Object.assign(this.configgens[this.editedIndex], this.editedItem)
        else if (this.pdp) Object.assign(this.configPDPs[this.editedIndex], this.editedItem)
        else if (this.holiday) Object.assign(this.configholidays[this.editedIndex], this.editedItem)
        /* else if (this.gml)
          Object.assign(this.configgmls[this.editedIndex], this.editedItem);*/

        this.editItem.domain = this.domain
        console.log("inside save item ", this.editedItem)
        try {
          if (this.gen)
            await configgenRef.doc(this.editedItem.uid).set({
              domain: this.editedItem.domain,
              description: this.editedItem.description,
              value: this.editedItem.value,
              comments: this.editedItem.comments,
            })
          else if (this.pdp) {
            
            await this.addempPDP()
            await configpdpRef.doc(this.editedItem.uid).set({
              domain: this.editedItem.domain,
              year: this.editedItem.year,
              eligibilitydate: this.editedItem.eligibilitydate,
              mailMessage:this.editedItem.mailMessage,
              status: this.editedItem.status,
            })
          } else if (this.holiday)
            await holidaysRef.doc(this.editedItem.uid).set({
              domain: this.editedItem.domain,
              date: this.editedItem.date,
              day: this.editedItem.day,
              remarks: this.editedItem.remarks,
              type: this.editedItem.type,
              year: this.editedItem.year,
            })

          this.snackbar = true
          this.snackbartext = "The Config has been updated successfully"
          this.close()
        } catch (error) {
          console.log("Error while update :", error)
          this.spinner = false
          this.alertflag = true
          this.alertmsg = "Error while update" + error
        }
      } // end of if
      else {
        this.editedItem.domain = this.domain // add this as domain name from login page.
        console.log("inside new item add", this.editedItem)

        try {
          if (this.gen) {
            this.editedItem.comments = this.configcomments

            await configgenRef
              .add(this.editedItem)
              .then((docRef) => {
                this.editedItem.uid = docRef.id
              })
              .catch((error) => console.error("Error adding document: ", error))
            this.configgens.push(this.editedItem)
          } else if (this.pdp) {
            await this.addempPDP()
            await configpdpRef
              .add(this.editedItem)
              .then((docRef) => {
                this.editedItem.uid = docRef.id
              })
              .catch((error) => console.error("Error adding document: ", error))
            this.configPDPs.push(this.editedItem)
          } else if (this.holiday) {
            await holidaysRef
              .add(this.editedItem)
              .then((docRef) => {
                this.editedItem.uid = docRef.id
              })
              .catch((error) => console.error("Error adding Holidays: ", error))
            this.configholidays.push(this.editedItem)
          }

          this.snackbar = true
          this.snackbartext = "New Config added successfully"
          this.close()
        } catch (error) {
          this.alertflag = true
          this.alertmsg = "Error while add" + error
          this.spinner = false
        }
      }
    },
    async addempPDP() {
      /* whild adding the new PDP year it will follwing 
            1. Take all emp from  emp table for this domain.
            2. check the new DOR,DOE date exisits.
            3. if it is not existis then compare the DOJ with current PDP year .
            4. if DOJ is less current PDP year then*/
      this.reviewpagemailarray = []
      var empdata = await empRef.where("domain", "==", this.domain).get()
      var PDPdata = await PDPRef.where("domain", "==", this.domain).get()
      var PDPdataarray = []
      for (var i = 0; i < PDPdata.docs.length; i++) {
        PDPdataarray.push({
          ...PDPdata.docs[i].data(),
          uid: PDPdata.docs[i].id,
        })
      }
      console.log("inside addempPDP ", empdata.docs.length, PDPdataarray.length)

      for (i = 0; i < empdata.docs.length; i++) {
        // console.log("inside for loop main",empdata.docs[i].data())
        console.log(
          "inside for loop",
          empdata.docs[i].data().officialDetails.email,
          empdata.docs[i].data().officialDetails.empDOR,
          empdata.docs[i].data().officialDetails.empDOE,
          empdata.docs[i].data().officialDetails.empDOJ,
          this.editedItem.eligibilitydate
        )
        // clientmgrname:'',   clientmgremail:'', clientmgrid:

        if (
          empdata.docs[i].data().officialDetails.empDOR == "" &&
          empdata.docs[i].data().officialDetails.empDOE == "" &&
          empdata.docs[i].data().officialDetails.empDOJ <= this.editedItem.eligibilitydate
        ) {
          console.log(
            "inside DOE and DOR NULL ",
            empdata.docs[i].data().officialDetails.clientmgrname,
            empdata.docs[i].data().officialDetails.clientmgremail
          )
          if (empdata.docs[i].data().officialDetails.clientmgrname) {
            var reviewername = empdata.docs[i].data().officialDetails.clientmgrname
          } else {
            reviewername = empdata.docs[i].data().officialDetails.mgrname
          }
          if (empdata.docs[i].data().officialDetails.clientmgremail) {
            var revieweremail = empdata.docs[i].data().officialDetails.clientmgremail
          } else {
            revieweremail = empdata.docs[i].data().officialDetails.mgremail
          }
          if (empdata.docs[i].data().officialDetails.clientmgrid) {
            var reviewerid = empdata.docs[i].data().officialDetails.clientmgrid
          } else {
            reviewerid = empdata.docs[i].data().officialDetails.mgrid
          }
          console.log("after reviewemails", reviewername, revieweremail)
          //var empdojyear=parseInt(empdata.docs[i].data().officialDetails.DOJ.substring(0, 4));
          var emppdpdata = PDPdataarray.filter((re) => re.email == empdata.docs[i].data().officialDetails.email)
          var emppdparray = []
          var idx = -1
          var reviewerstatus = ""

          var pdppageid = reviewerid + "-" + empdata.docs[i].data().officialDetails.empid
          if (this.editedItem.status == "Half Yearly Open" || this.editedItem.status == "Year End Open") {
            reviewerstatus = "Open"
          }
          var pdbobj = {
            objectives: "",
            h1positives: "",
            h1improvements: "",
            h1comments: "",
            h2positives: "",
            h2improvements: "",
            h2comments: "",
            addtionalcomments: "",
            nextyearobjectives: "",
            year: this.editedItem.year,
            reviewername: reviewername,
            revieweremail: revieweremail,
            reviewerid: reviewerid,
            pdppageid: pdppageid,
            status: this.editedItem.status,
            reviewerstatus: reviewerstatus,
            createdon: new Date().toISOString().substring(0, 10),
            modifiedon: new Date().toISOString().substring(0, 10),
            empid: empdata.docs[i].data().officialDetails.empid,
            name: empdata.docs[i].data().officialDetails.name,
            email: empdata.docs[i].data().officialDetails.email,
          }
          console.log("after filter emppdpdata-length", emppdpdata.length)
          if (emppdpdata.length > 0) {
            emppdparray = emppdpdata[0].pdp

            idx = emppdparray.findIndex((obj) => obj.year == this.editedItem.year)
            console.log("after findIndex idx is", idx)
            if (idx > -1) {
              pdbobj = Object.assign({}, emppdparray[idx])
              reviewerstatus = pdbobj.reviewerstatus
              // below will check the review status and if it is new status then change review status as open.
              if (this.editedItem.status == "Half Yearly Open" || this.editedItem.status == "Year End Open") {
                if (pdbobj.status != this.editedItem.status) {
                  reviewerstatus = "Open"
                  pdbobj.reviewerstatus = reviewerstatus
                }
              }
              pdbobj.status = this.editedItem.status // set the current values
              pdbobj.reviewername = reviewername
              pdbobj.revieweremail = revieweremail
              pdbobj.reviewerid = reviewerid
              pdbobj.pdppageid = pdppageid
              pdbobj.name = empdata.docs[i].data().officialDetails.name
            } // end of idx >-1
            /* the below moduel will check the half yearly open if its then take the nextyear objective from pervious year and update*/
            else if (this.editedItem.status == "Half Yearly Open") {
              var intperviousyear = parseInt(this.editedItem.year) - 1
              var strperviousyear = intperviousyear.toString()
              var preidx = emppdparray.findIndex((obj) => obj.year == strperviousyear)
              if (preidx > -1) {
                pdbobj.objectives = emppdparray[preidx].nextyearobjectives
              }
            } // end of to assign the next year objectives
          } // end of if emppdpdata.length>0

          var tmparray = {}
          tmparray = {
            ...pdbobj,
          }
          if (idx > -1) {
            Object.assign(emppdparray[idx], tmparray)
          } else {
            emppdparray.push({ ...tmparray })
          }
          console.log("after emppdparray", emppdparray)
          await PDPRef.doc(empdata.docs[i].data().officialDetails.email).set({
            email: empdata.docs[i].data().officialDetails.email,
            domain: this.domain,
            name: empdata.docs[i].data().officialDetails.name,
            reviewername: reviewername,
            revieweremail: revieweremail,
            reviewerid: reviewerid,
            pdppageid: pdppageid,
            reviewerstatus: reviewerstatus,
            pdp: emppdparray,
            empid: empdata.docs[i].data().officialDetails.empid,
          })
          if (reviewerstatus == "Open") {
            var reviewpage = { name: empdata.docs[i].data().officialDetails.name, reviewpageid: pdppageid }
            idx = this.reviewpagemailarray.findIndex((obj) => obj.revieweremail == revieweremail)
            var tmpmailarray = []
            if (idx > -1) {
              tmpmailarray = this.reviewpagemailarray[idx].maillink
              tmpmailarray.push({ ...reviewpage })
              this.reviewpagemailarray[idx].maillink = tmpmailarray
            } else {
              tmpmailarray.push({ ...reviewpage })
              this.reviewpagemailarray.push({
                revieweremail: revieweremail,
                reviewername: reviewername,
                year: this.editedItem.year,
                maillink: tmpmailarray,
              })
            }
          } // end of reviewerstatus open
        } // end of DOR and DOE are  null and DOJ less than eligibilitydate
      } // end of for loop for main empref .
      for (i = 0; i < this.reviewpagemailarray.length; i++) {
        console.log(
          "reviewpagemailarray",
          i,
          this.reviewpagemailarray[i].revieweremail,
          this.reviewpagemailarray[i].maillink
        )
        //if( reviewerstatus == "Open")
        console.log("this.editedItem.status",this.editedItem.status)
        if (this.editedItem.status == "Half Yearly Open" || this.editedItem.status == "Year End Open") {
          this.mailfun(this.reviewpagemailarray[i])
          }
         
      }
    }, //addempPDP

    mailfun(maildetails) {
      var mailmsg = { to: "", subject: "", message: "" }

      mailmsg.to = maildetails.revieweremail 
      mailmsg.message = "Hi " + maildetails.reviewername + "," + "<br>" + "<br>"


       if (this.editedItem.status == "Half Yearly Open"){
    
          mailmsg.subject = "Mid-year "
         // mailmsg.message += "Mid-Year "
    
        }  else if(this.editedItem.status == "Year End Open"){
           
             mailmsg.subject = "Year-end "
            
        }
      mailmsg.subject += "Performance Review of Venzo Employees"

      mailmsg.message+=this.editedItem.mailMessage
      
     /* mailmsg.message +=
        "Performance Review Process for the year " +
        maildetails.year +
        " has been initiated for Venzo Employees. Kindly Click the link against each employee and fill in your review comments." +
        "<br>" +
        "<br>"
      mailmsg.message +=
        "You will receive a weekly reminder (similar email as this one) until completion of the review." +
        "<br>" +
        "<br>"

         mailmsg.message +=
        "Some of the employee(s) may have started to work with us only this year, and request you to update the Objectives for 2021 as well." +
        "<br>" +
        "<br>"*/

      mailmsg.message += "Performance Review is Pending For" + "<br>" + "<br>"
      for (var i = 0; i < maildetails.maillink.length; ++i) {
        var pagelink = authdomain + "reviewpdp/" + maildetails.maillink[i].reviewpageid
        mailmsg.message += maildetails.maillink[i].name + " - "
        mailmsg.message += '<a href="' + pagelink + '">Click here</a>'
        mailmsg.message += "<br>"
      }
      mailmsg.message += "<br>"
      mailmsg.message += "<br>"
      if (mailmsg.to.includes("surecomp"))
      mailmsg.message +=
        "If any of the above venzo Employee(s) not reporting to you, send email to Sagit.Ramati@surecomp.com and hrteam@venzotechnologies.com"
      else
      mailmsg.message +=
        "If any of the above venzo Employee(s) not reporting to you, send email to  hrteam@venzotechnologies.com"

      mailmsg.message += "<br>"
      mailmsg.message += "<br>"
      mailmsg.message += "Note: Please Open the link in Chrome"
      mailmsg.message += "<br>"
      mailmsg.message += "<br>"
      mailmsg.message += "Regards," + "<br>" + "Venzo HR Team"
      //console.log(" before calling sendemail",mailmsg.to)
      this.sendEmail(mailmsg)
      //console.log("mailResult",mailResult,mailmsg.to)
    },

    async updatesumarray(tmpdate) {
      var year = tmpdate.substring(0, 4)
      var month = parseInt(tmpdate.substring(5, 7))
      var querystring = await configgenRef.where("description", "==", "Per Month Leave").get()
      var leavepermonth = parseInt(querystring.docs[0].data().value)
      console.log("inside updatesummary", year, month)
      querystring = await leavesummariesRef.where("domain", "==", this.domain).where("year", "==", year).get()
      for (var i = 0; i < querystring.docs.length; ++i) {
        var uid = querystring.docs[i].id
        var creditedbalance = parseInt(querystring.docs[i].data().creditedbalance) + leavepermonth
        var closingbalance = parseInt(querystring.docs[i].data().creditedbalance) + leavepermonth
        await leavesummariesRef.doc(uid).update({
          creditedbalance: creditedbalance,
          closingbalance: closingbalance,
        })
      }
    },
  },
}

</script>

<style scoped>
.tiptap-vuetify-editor .ProseMirror {
  min-height: 1000px;
}
</style>
